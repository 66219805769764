import { auth, useSession } from '@shared/misc';

import { withErrorHandling, getApiHeaders } from '../helpers';
import { fetchData } from '../helpers/fetch-data';
import { getPublicEnvironmentVariables } from '../helpers/get-public-environment-variables';
import { type CustomerProfile } from '../types';

import { REVALIDATE_USER_DATA } from './constants/revalidate-time';
import { OrgSharedDataTag } from './constants/tags';

export const getCustomerProfile = async ({
  personId,
}: {
  personId?: number;
}): Promise<CustomerProfile> => {
  const session = await auth();

  return withErrorHandling(
    await fetchData({
      apiUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/CustomerProfile/${personId}`,
      headers: getApiHeaders(session),
      next: {
        revalidate: REVALIDATE_USER_DATA,
        tags: [
          OrgSharedDataTag.GET_CUSTOMER_PROFILE,
          `${OrgSharedDataTag.GET_CUSTOMER_PROFILE}_${personId}`,
        ],
      },
    }),
    // eslint-disable-next-line no-template-curly-in-string
    '${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/CustomerProfile/${personId}'
  );
};

export const useGetNullableCustomerProfile = () => {
  const session = useSession();

  return {
    getNullableCustomerProfile: async ({
      personId,
    }: {
      personId?: number;
    }): Promise<CustomerProfile | null> => {
      const environment = await getPublicEnvironmentVariables();

      const result = await fetch(
        `${environment.apiBaseUrl}/${environment.apiRoute}/CustomerProfile/${personId}`,
        {
          headers: {
            Authorization: session ? `Bearer ${session?.data?.accessToken}` : '',
            'Ocp-Apim-Subscription-Key': environment.ocpApiSubscriptionKey ?? '',
          },
        }
      );

      if (result.status === 404) {
        return null;
      }

      return withErrorHandling(
        result,
        // eslint-disable-next-line no-template-curly-in-string
        '${environment.apiBaseUrl}/${environment.apiRoute}/CustomerProfile/${personId}'
      );
    },
  };
};
