export enum ContentType {
  Default = 0,
  Article = 1,
  Guide = 2,
  Playlist = 3,
  Podcast = 4,
  Audio = 5,
  Event = 6,
  Store = 7,
  SiteContent = 8,
  PressRelease = 9,
  Marketing = 10,
  Video = 11,
  Text = 12,
  Pdf = 13,
  WordDocument = 14,
  Image = 15,
}
