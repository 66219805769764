import { QueryClient } from '@tanstack/react-query';

import {
  type LandingPageContentQuery,
  useLandingPageContentQuery,
} from '../_generated/gql-generated';
import { getContentInCurrentLanguage } from '../utils/get-content-in-current-language';

export const getLandingPageContent = async () => {
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery<LandingPageContentQuery>({
    queryFn: useLandingPageContentQuery.fetcher(),
    queryKey: useLandingPageContentQuery.getKey(),
  });

  return (await getContentInCurrentLanguage(data?.HomePageV2?.items))?.[0];
};

export type LandingPageContent = Awaited<
  Promise<PromiseLike<ReturnType<typeof getLandingPageContent>>>
>;
