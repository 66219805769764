import { QueryClient } from '@tanstack/react-query';

import {
  type DynamicContentQuery,
  type DynamicContentQueryVariables,
  useDynamicContentQuery,
} from '../_generated/gql-generated';

export const getDynamicContent = async (variables: DynamicContentQueryVariables) => {
  const queryClient = new QueryClient();

  return await queryClient.fetchQuery<DynamicContentQuery>({
    queryFn: useDynamicContentQuery.fetcher(variables),
    queryKey: useDynamicContentQuery.getKey(variables),
  });
};

export type DynamicContent = Awaited<Promise<PromiseLike<ReturnType<typeof getDynamicContent>>>>;
