import classNames from 'classnames';
import { type InputHTMLAttributes, type ReactElement } from 'react';

import styles from './input.module.scss';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  leadingIcon?: ReactElement;
  trailingIcon?: ReactElement;
};

export const Input = ({
  className,
  label,
  leadingIcon,
  name,
  placeholder,
  trailingIcon,
  ...rest
}: InputProps) => {
  return (
    <div className={styles.wrapper}>
      {leadingIcon && (
        <div className={classNames(styles.input__icon, styles['input__icon--leading'])}>
          {leadingIcon}
        </div>
      )}
      {trailingIcon && (
        <div className={classNames(styles.input__icon, styles['input__icon--trailing'])}>
          {trailingIcon}
        </div>
      )}
      <input
        className={classNames(
          styles.input,
          {
            [styles['input--with-trailing-icon']]: Boolean(trailingIcon),
            [styles['input--with-leading-icon']]: Boolean(leadingIcon),
            [styles['input--without-label']]: !label,
          },
          className
        )}
        id={name}
        name={name}
        placeholder={label ? '' : placeholder}
        {...rest}
      />
      <label className={styles.input__label} htmlFor={name}>
        {(label || placeholder) ?? ''}
      </label>
    </div>
  );
};
