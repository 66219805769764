import { QueryClient } from '@tanstack/react-query';

import {
  type SiteSettingsCategoryAndSourceRootsQuery,
  useSiteSettingsCategoryAndSourceRootsQuery,
} from '../_generated/gql-generated';

export const getSiteSettingsCategoryAndSourceRoots = async () => {
  const queryClient = new QueryClient();

  return await queryClient.fetchQuery<SiteSettingsCategoryAndSourceRootsQuery>({
    queryFn: useSiteSettingsCategoryAndSourceRootsQuery.fetcher(),
    queryKey: useSiteSettingsCategoryAndSourceRootsQuery.getKey(),
  });
};

export type SiteSettingsCategoryAndSourceRoots = Awaited<
  Promise<PromiseLike<ReturnType<typeof getSiteSettingsCategoryAndSourceRoots>>>
>;
