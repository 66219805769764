import { redirect } from 'next/navigation';

import { logErrorMessage } from '@shared/utils';

import { ERROR_PATH, NOT_AUTHORIZED_PATH } from '../constants';

export const withErrorHandling = async (response: Response, endpointIdentifier: string) => {
  if (response?.status !== 200) {
    logErrorMessage(
      `${response.url} returned status ${response?.status}, ${await response.text()}`,
      [endpointIdentifier, response?.status?.toString() ?? '0']
    );
    if (response?.status === 403) {
      redirect(NOT_AUTHORIZED_PATH);
    } else {
      redirect(ERROR_PATH);
    }
  }

  return await response.json();
};
