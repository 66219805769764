import { QueryClient } from '@tanstack/react-query';

import { useSiteLanguagesQuery } from '../_generated/gql-generated';

export const getLocalesList = async () => {
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery({
    queryFn: useSiteLanguagesQuery.fetcher(),
    queryKey: useSiteLanguagesQuery.getKey(),
  });

  return data.SiteDefinition?.items?.[0]?.Languages?.map((language) => language?.UrlSegment);
};
