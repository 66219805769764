// eslint-disable-next-line canonical/filename-match-regex
import { useMessages } from 'next-intl';
import { type LocalePrefix } from 'next-intl/dist/types/src/shared/types';
import { createSharedPathnamesNavigation } from 'next-intl/navigation';

import { locales } from './locales';

export const localePrefix: LocalePrefix = 'as-needed';

export const { Link, redirect, usePathname, useRouter } = createSharedPathnamesNavigation({
  localePrefix,
  locales,
});

export const useAppMessages = () => {
  return useMessages() as IntlMessages;
};
