import { QueryClient } from '@tanstack/react-query';

import {
  type LearnSearchResultsQuery,
  type LearnSearchResultsQueryVariables,
  useLearnSearchResultsQuery,
} from '../_generated/gql-generated';

export const getLearnSearchResults = async (searchVariables: LearnSearchResultsQueryVariables) => {
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery<LearnSearchResultsQuery>({
    queryFn: useLearnSearchResultsQuery.fetcher(searchVariables),
    queryKey: useLearnSearchResultsQuery.getKey(searchVariables),
  });

  return {
    facets: data.LearnDetailPage?.facets,
    items: data.LearnDetailPage?.items,
    total: data.LearnDetailPage?.total || 0,
  };
};

export type LearnSearchResults = Awaited<
  Promise<PromiseLike<ReturnType<typeof getLearnSearchResults>>>
>;
