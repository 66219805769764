import { logErrorMessage } from '@shared/utils';

import { OrgSharedDataTag } from '../constants/tags';

type FetchOptions = {
  cache?: RequestCache;
  next?: NextFetchRequestConfig;
};

type RequestInit = {
  headers: (HeadersInit & FetchOptions) | FetchOptions;
};

export const gqlFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers']
) => {
  return async (): Promise<TData> => {
    const { next, cache = 'force-cache', ...restOptions } = options || {};
    const response = await fetch(`https://cg.optimizely.com/content/v2`, {
      body: JSON.stringify({ query, variables }),
      cache,
      headers: {
        Authorization:
          'Basic ' +
          btoa(
            process.env.OPTIMIZELY_GRAPHQL_APP_KEY + ':' + process.env.OPTIMIZELY_GRAPHQL_SECRET
          ),
        'cg-roles': ['Everyone', 'Administrators'] as unknown as string,
        'Content-Type': 'application/json',
        ...restOptions,
      },
      method: 'POST',
      next: {
        ...next,
        tags: [...(next?.tags ? next.tags : []), OrgSharedDataTag.GET_OPTIMIZELY_CONTENT],
      },
    });

    if (!response.ok) {
      logErrorMessage(
        `gql-fetcher fetch returned invalid response code ${
          response.status
        } ${await response.text()}`
      );
      throw new Error('Something went wrong, please try again later.');
    }

    try {
      const json = await response.json();

      if (json.errors) {
        const { message } = json.errors[0];

        throw new Error(message);
      }

      return json.data;
    } catch (error) {
      logErrorMessage(
        `gql-fetcher failed to parse as json or error was returned: ${await response.text()}`
      );
      throw error;
    }
  };
};
