import {
  QueryCache,
  QueryClient,
  type QueryKey,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';

import { ToastType, useGlobalToastContext } from '@shared/ui-components';

import { useGlobalCmsContentContext } from '../../components/providers/global-cms-context';

export const useQueryHandler = <
  TQueryFunctionData = unknown,
  TError = unknown,
  TData = TQueryFunctionData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: UseQueryOptions<TQueryFunctionData, TError, TData, TQueryKey>
) => {
  const { addToast } = useGlobalToastContext();
  const { cmsContent } = useGlobalCmsContentContext();

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => {
        addToast(
          {
            closeButtonLabel: cmsContent.closeButtonLabel,
            description: error.message,
            type: ToastType.ERROR,
          },
          { timeout: 0 }
        );
      },
    }),
  });

  return useQuery(options, queryClient);
};
