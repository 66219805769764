import { QueryClient } from '@tanstack/react-query';

import { type StaticPageQuery, useStaticPageQuery } from '../_generated/gql-generated';
import { getContentInCurrentLanguage } from '../utils';

export const getStaticPageContent = async (relativePath: string) => {
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery<StaticPageQuery>({
    queryFn: useStaticPageQuery.fetcher({ relativePath }),
    queryKey: useStaticPageQuery.getKey({ relativePath }),
  });

  const staticPageContent = (await getContentInCurrentLanguage(data?.NextStaticPage?.items))?.[0];
  const siteSettingsPageContent = (
    await getContentInCurrentLanguage(data?.SiteSettingsPage?.items)
  )?.[0];

  return {
    ...staticPageContent,
    SiteSettingsPageContent: {
      ...siteSettingsPageContent,
    },
  };
};

export type StaticPageContent = Awaited<
  Promise<PromiseLike<ReturnType<typeof getStaticPageContent>>>
>;
