import { QueryClient } from '@tanstack/react-query';

import { type HomePageMetadataQuery, useHomePageMetadataQuery } from '../_generated/gql-generated';
import { getContentInCurrentLanguage } from '../utils/get-content-in-current-language';

export const getHomePageMetadata = async () => {
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery<HomePageMetadataQuery>({
    queryFn: useHomePageMetadataQuery.fetcher(),
    queryKey: useHomePageMetadataQuery.getKey(),
  });

  return (await getContentInCurrentLanguage(data.HomePageV2?.items))?.[0];
};

export type HomePageMetadata = Awaited<
  Promise<PromiseLike<ReturnType<typeof getHomePageMetadata>>>
>;
